import React from 'react'

import Box from '../styled/Box'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box textAlign="center">
      <Box as="h1">Not Found!</Box>
      <Box as="p">
        You appear to have stumbled upon a page that no longer exists.
      </Box>
    </Box>
  </Layout>
)

export default NotFoundPage
